import React, { Component } from "react";
import Logo from "../../assets/images/rosio-logo.png";
import ServicesBttn from "../../assets/images/services-button.png";
import ContactBttn from "../../assets/images/contact-button.png";
import { NavLink } from "react-router-dom";
import "../Nav/Nav.scss";

export default class Nav extends Component {
  render() {
    return (
      <div className="NavBar">
        <NavLink to="/">
          <img src={Logo} className="NavBar__logo" alt="logo" />
        </NavLink>
        <div className="NavBar__buttons">
          <NavLink to="/services">
            <img
              src={ServicesBttn}
              className="NavBar__services"
              alt="Upholstery, Flooring, Furnishing, Restoring, Exotic cars, Rvs, Houses, Boats, and Yachts!"
            />
          </NavLink>
          <NavLink to="/contact">
            <img src={ContactBttn} className="NavBar__contact" alt="logo" />
          </NavLink>
        </div>
      </div>
    );
  }
}
