import React, { Component } from "react";
import "../Contact/Contact.scss";
import Applynow from "../../assets/images/careers-experienced-team-member.png";
import { NavLink } from "react-router-dom";

export default class Contact extends Component {
  render() {
    return (
      <div className="contact-main">
        <div className="contact-container">
          <p className="contact-title">Email</p>
          <p className="contact-info">
            Design builds:
            <span className="contact-info-email">design@rosio.co</span>
            <br />
            General inquires:
            <span className="contact-info-email">quotes@rosio.co</span>
            <br />
            Customer service:
            <span className="contact-info-email">support@rosio.co</span>
          </p>
          <p className="contact-title">Careers</p>
          <div className="contact-careers-container">
            <img
              src={Applynow}
              className="contact-careers"
              alt="rosio upholstery. design, build, and install! Yachts, Exotic Cars, Jets!"
            />
            <p className="contact-careers-apply-today-button">
              <NavLink
                to="/careers"
                className="contact-careers-apply-today-click"
              >
                Apply Today!
              </NavLink>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
