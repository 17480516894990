import React from "react";
import "./App.scss";
import Hero from "./assets/images/hero-vid-rosio.gif";
import Beacon from "./assets/images/meissen-coin.gif";
import Abea from "./assets/images/abea-leather-partners.png";

function App() {
  return (
    <div className="app">
      <div>
        <img
          src={Hero}
          className="app-hero"
          alt="rosio upholstery. design, build, and install! Yachts, Exotic Cars, Jets!"
        />
      </div>
      <div className="app-info-container">
        <p className="app-info-top">
          We are servicing the South Florida Region!
        </p>
        <p className="app-info-top">
          Our team designs, build, and installs for:
        </p>
        <p className="app-info">
          -Exotic Cars
          <br />
          -Classic Cars
          <br />
          -RVs
          <br />
          -Golf Carts
          <br />
          -Yachts
          <br />
          -Boats
          <br />
          -Millitary Grade Helicopters
          <br />
          -Jet Planes/Private Planes
          <br />
          -Custom Furniture &amp; Accessories
        </p>
        {/* <p className="app-info-bottom">
          Special request for furniture restorations and custom built
          accessories are always welcome!
        </p> */}

        <div className="app-abea-div">
          <a href="https://www.abealeather.com/">
            <img
              src={Abea}
              className="app-abea"
              alt="Download Marine Beacon on the Apple App Store and Google PlayStore Today! rosio is featured as a top tier service provider!"
            />
          </a>
          <p className="app-abea-p">Official Partners of Abea Leather Inc.</p>
          <p className="app-abea-p">
            The highest grade quality in hides and vinyls!
          </p>
        </div>
        <img
          src={Beacon}
          className="app-beacon"
          alt="Download Marine Beacon on the Apple App Store and Google PlayStore Today! rosio is featured as a top tier service provider!"
        />
        <p className="app-info-signed-off">
          Top tier Marine Beacon upholstery service provider!
          <br />
          rosio © 2022
        </p>
      </div>
    </div>
  );
}

export default App;
