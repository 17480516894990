import React from "react";
import "../Careers/Careers.scss";
import { useForm, ValidationError } from "@formspree/react";
function ContactForm() {
  const [state, handleSubmit] = useForm("xwkyrnww");
  if (state.succeeded) {
    return (
      <div className="careers-upload">
        <p className="careers-upload-note">
          Thanks for your interest in joining rosio! We look forward to growing
          the team!
        </p>
      </div>
    );
  }
  return (
    <form onSubmit={handleSubmit} className="careers-form">
      <div className="careers-form-container">
        <div className="careers-applicant">
          <label htmlFor="applicant" className="careers-label">
            Name
          </label>
          <input
            required
            id="applicant"
            type="applicant"
            name="applicant"
            placeholder="Full Name"
            className="careers-input"
          />
        </div>

        <div className="careers-positions">
          <label htmlFor="position" className="careers-label">
            Jobs
          </label>
          <select
            required
            id="positions"
            name="positions"
            className="careers-input"
          >
            <option value="">Now hiring below</option>
            <option value="district manager">District Manager</option>
            <option value="marketing specialist">Marketing Specialist</option>
            <option value="upholsterer apprentice">
              Upholsterer Apprentice
            </option>
          </select>
        </div>

        <div className="careers-resume">
          <label htmlFor="resume" className="careers-label">
            Resume
          </label>
          {/* <input
            type="file"
            id="resume"
            name="resume"
            className="careers-resume-input"
          /> */}
          <p className="careers-resume-temp">📄</p>
          <p className="careers-resume-temp-statement">
            Please email resume to careers@rosio.co
          </p>
        </div>

        <div className="careers-phone">
          <label htmlFor="phone" className="careers-label">
            Contact Number
          </label>
          <input
            required
            id="phone"
            type="phone"
            name="phone"
            placeholder="(000) 000-0000"
            className="careers-input"
          />
        </div>

        <div className="careers-email">
          <label htmlFor="email" className="careers-label">
            Email Address
          </label>
          <input
            required
            id="email"
            type="email"
            name="email"
            placeholder="name@address.com"
            className="careers-input"
          />
          <ValidationError prefix="Email" field="email" errors={state.errors} />
        </div>

        <div className="careers-referral">
          <label htmlFor="referral" className="careers-label">
            Referred By
          </label>
          <input
            id="referral"
            name="referral"
            placeholder="If applicable"
            className="careers-input"
          />
          <ValidationError
            prefix="Message"
            field="message"
            errors={state.errors}
          />
        </div>

        <button
          type="submit"
          disabled={state.submitting}
          className="careers-apply-button"
        >
          Apply
        </button>
      </div>
    </form>
  );
}
function Careers() {
  return <ContactForm />;
}
export default Careers;
