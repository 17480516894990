import React, { Component } from "react";
import "../Services/Services.scss";
import Pone from "../../assets/images/pro-line-01.png";
import Ptwo from "../../assets/images/pro-line-02.png";
import Pthree from "../../assets/images/pro-line-03.png";
import Modelbthree from "../../assets/images/model-b3-marcel-breuer.png";

export default class Services extends Component {
  render() {
    return (
      <div className="services-main">
        <div className="services-proline-container">
          <img
            src={Pone}
            className="services-proline"
            alt="rosio upholstery. design, build, and install! Yachts, Exotic Cars, Jets!"
          />
          <img
            src={Ptwo}
            className="services-proline"
            alt="rosio upholstery. design, build, and install! Yachts, Exotic Cars, Jets!"
          />
          <img
            src={Pthree}
            className="services-proline"
            alt="rosio upholstery. design, build, and install! Yachts, Exotic Cars, Jets!"
          />
          <p className="services-proline-info">Dual console bowrider</p>
        </div>
        <div className="services-modelbthree-container">
          <img
            src={Modelbthree}
            className="services-modelbthree"
            alt="rosio upholstery. design, build, and install! Yachts, Exotic Cars, Jets!"
          />
          <p className="services-modelbthree-info">
            Marcel Breuer - Model b3 w/ accessory pillows &#40;2&#41;
          </p>
        </div>
      </div>
    );
  }
}
